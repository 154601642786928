import { dev } from '$app/environment';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: dev ? 'development' : 'production',
	release: `${__APP_NAME__}@${__APP_VERSION__}`,
	tracesSampleRate: 1.0,
	integrations: [Sentry.captureConsoleIntegration()],
	enabled: !dev
});

export const handleError = Sentry.handleErrorWithSentry();
